


















import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component({
    components: {
        FormBase: () => import('@/components/forms/Base.vue'),
        FormInput: () => import('@/components/forms/Input.vue'),
        ElButton: () => import('@/components/elements/Button.vue'),
    },
})
export default class ContactFormSimple extends Vue {
    i18n: string = 'contact';

    success: boolean = false;

    $refs!: Vue['$refs'] & {
        contactForm: FormClass;
    };

    form: { [key: string]: {} | null } = {
        email: '',
        message: '',
        locale: 'nl_BE',
    };

    contactSubmit() {
        axios
            .post(`${process.env.VUE_APP_API_URL}contact-requests`, this.form)
            .then(() => {
                this.success = true;
            })
            .catch((e: ErrorResponse) => this.$refs.contactForm.handleErrors(e));
    }
}
